import React from "react";
import css from './Pagination.module.css';

function PaginationArrow({
    onRefine,
    direction,
    isDisabled,
    currentPage
}) {

    const icon =
        direction === 'left' ? (
            <span><svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <g clip-path="url(#clip0_3950_112)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.40006 0C8.57046 0 8.73906 0.0650005 8.85846 0.192293C9.07206 0.419795 9.04026 0.761589 8.78766 0.955507L1.52941 6.50005L8.78766 12.0446C9.04026 12.2385 9.07206 12.5803 8.85846 12.8083C8.64366 13.0369 8.26566 13.0646 8.01246 12.8723L0.212401 6.91389C0.0774002 6.81043 0 6.6593 0 6.50005C0 6.3408 0.0774002 6.18967 0.212401 6.08621L8.01246 0.127834C8.12526 0.0422506 8.26326 0 8.40006 0Z" fill="#EB001B" />
                    <path d="M8.40006 0C8.57046 0 8.73906 0.0650005 8.85846 0.192293C9.07206 0.419795 9.04026 0.761589 8.78766 0.955507L1.52941 6.50005L8.78766 12.0446C9.04026 12.2385 9.07206 12.5803 8.85846 12.8083C8.64366 13.0369 8.26566 13.0646 8.01246 12.8723L0.212401 6.91389C0.0774005 6.81043 0 6.6593 0 6.50005C0 6.3408 0.0774005 6.18967 0.212401 6.08621L8.01246 0.127834C8.12526 0.0422503 8.26326 0 8.40006 0" stroke="#EB001B" />
                </g>
                <defs>
                    <clipPath id="clip0_3950_112">
                        <rect width="9" height="13" fill="white" />
                    </clipPath>
                </defs>
            </svg></span>
        ) : (
            <span><svg xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
                <g clip-path="url(#clip0_3950_110)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.599942 13C0.429541 13 0.26094 12.935 0.141539 12.8077C-0.0720627 12.5802 -0.0402625 12.2384 0.212339 12.0445L7.47059 6.49995L0.212339 0.955407C-0.0402625 0.761489 -0.0720627 0.419695 0.141539 0.191652C0.35634 -0.0369336 0.734343 -0.0645588 0.987545 0.127734L8.7876 6.08611C8.9226 6.18957 9 6.3407 9 6.49995C9 6.6592 8.9226 6.81033 8.7876 6.91379L0.987545 12.8722C0.874744 12.9577 0.736743 13 0.599942 13Z" fill="#EB001B" />
                    <path d="M0.599942 13C0.429541 13 0.26094 12.935 0.141539 12.8077C-0.0720627 12.5802 -0.0402625 12.2384 0.212339 12.0445L7.47059 6.49995L0.212339 0.955407C-0.0402625 0.761489 -0.0720627 0.419695 0.141539 0.191652C0.35634 -0.0369336 0.734343 -0.0645588 0.987545 0.127734L8.7876 6.08611C8.9226 6.18957 9 6.3407 9 6.49995C9 6.6592 8.9226 6.81033 8.7876 6.91379L0.987545 12.8722C0.874744 12.9577 0.736743 13 0.599942 13" stroke="#EB001B" />
                </g>
                <defs>
                    <clipPath id="clip0_3950_110">
                        <rect width="9" height="13" fill="white" />
                    </clipPath>
                </defs>
            </svg></span>
        );

    return (<button
        className={css.arrowBtn}
        onClick={() => {
            if (direction === 'left') {
                onRefine(currentPage - 1)
            } else {
                onRefine(currentPage + 1)
            }
        }}
        disabled={isDisabled}
    >
        {icon}
    </button>)
}



export default PaginationArrow;