import React, { useEffect, useState } from 'react';
import { useHits, useInstantSearch } from 'react-instantsearch';
import ListingHit from './ListingHit';
import CreatorHit from './CreatorHit';
import Slider from "react-slick";
import css from './Hits.module.css';
import { Button, CustomArrow } from '../../../components';
import { hitsViewed } from '../../../util/searchInsight';

const listingIndex = process.env.REACT_APP_ALGOLIA_LISTINGS_INDEX;
const creatorIndex = process.env.REACT_APP_ALGOLIA_USERS_INDEX;

function CustomHits(props) {
  const {
    routeConfiguration,
    history,
    indexName,
    intl,
    isLandingPage,
    currentUser
  } = props;

  const { items, results, sendEvent } = useHits(props);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [slideCount, setSlideCount] = React.useState(0);
  const [screenWidth, setScreenWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  const queryID = results && results.queryID;

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setScreenWidth(window.innerWidth);

    };

    window.addEventListener('resize', handleResize);

    // Initial log
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const itemsLength = items.length;
  useEffect(() => {
    setSlideCount(itemsLength);
    setCurrentSlide(0);

  }, [itemsLength]);

  const itemIds = items.map(hit => hit.objectID);
  const currentUserId = currentUser?.id?.uuid;
  useEffect(() => {
    // Send view event
    if (itemIds && itemIds.length) {
      hitsViewed({
        userToken: currentUserId,
        indexName,
        eventName: 'Hits Viewed',
        objectIDs: itemIds
      });
    }
  }, [itemIds, currentUserId, indexName]);


  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: false,
    swipe: false,
    prevArrow: <CustomArrow direction="prev" disabled={currentSlide === 0} />,
    nextArrow: <CustomArrow direction="next" disabled={currentSlide >= slideCount - 4} />, // Assuming 4 visible items
    beforeChange: (current, next) => setCurrentSlide(next),
    afterChange: current => setCurrentSlide(current),
    onInit: () => setSlideCount(items.length),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          arrows: false,
          swipe: true,
          swipeToSlide: true,
        },
      },
    ],
  };

  const getWidth = () => {
    return screenWidth <= 767 ? 263 : 295;
  };

  return (
    <>
      {isLandingPage ? (
        <div className={css.hitsContainer}>
          <Slider {...settings}>
            {items.map(hit => (
              <div
                style={{ width: getWidth() }}
                key={hit.objectID}
                onClick={() => sendEvent('click', hit, 'Hit Clicked', { userToken: currentUser?.id?.uuid })}
              >
                <div style={{ wordBreak: 'break-all' }}>
                  {indexName === listingIndex || indexName === `${listingIndex}_timestamp_desc` ? (
                    <ListingHit
                      hit={hit}
                      routeConfiguration={routeConfiguration}
                      history={history}
                      intl={intl}
                      queryID={queryID}
                    />
                  ) : indexName === creatorIndex ? (
                    <CreatorHit
                      hit={hit}
                      routeConfiguration={routeConfiguration}
                      history={history}
                      intl={intl}
                      queryID={queryID}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <ul className={css.hits}>
          {items.map(hit => (
            <li
              key={hit.objectID}
              onClick={() => sendEvent('click', hit, 'Hit Clicked', { userToken: currentUser?.id?.uuid })}
            >
              <div style={{ wordBreak: 'break-all' }}>
                {indexName === listingIndex ? (
                  <ListingHit
                    hit={hit}
                    routeConfiguration={routeConfiguration}
                    history={history}
                    intl={intl}
                    queryID={queryID}
                  />
                ) : indexName === creatorIndex ? (
                  <CreatorHit
                    hit={hit}
                    routeConfiguration={routeConfiguration}
                    history={history}
                    intl={intl}
                    queryID={queryID}
                  />
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default CustomHits;